import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.less";
import { useHistory } from "react-router-dom";
import { getQueryString } from "../../utils/getQueryString";
import { oauthAppinfoApi, oauthAuthorizeApi, oauthAuthinfoApi } from "../../services/oauth";
import { loginApi } from "../../services/login";
import { getToken, setToken } from "../../utils/auth";
import userHead from "../../assets/logo/userHead.svg";
import StatusCode from "../../utils/statusCode";
import { EmailRegular, PassWordRegular } from "../../utils/regularExpression";

interface IAppInfo {
    client_id: string;
    desc: string;
    homepage: string;
    logo: string;
    name: string;
    open: boolean;
    state?: string;
}

interface ResAuthorizeData {
    code: string;
    state: string;
}

const OauthLogin = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const confirmId: string | null = getQueryString("client_id");
    const stateValue: string | null = getQueryString("state");
    const redirectUri: string | null = getQueryString("redirect_uri");
    const [appInfo, setAppInfo] = useState<IAppInfo>();
    const [errorInfo, setInfoError] = useState("");
    const [ent, setEnt] = useState(false);
    React.useEffect(() => {
        document.title = "zhifou Club";
    }, []);
    useEffect(() => {
        if (confirmId !== null) {
            void oauthAppinfoApi({ client_id: confirmId })
                .then((info) => {
                    const { data } = info as { data: IAppInfo };
                    setAppInfo({ ...data, open: false });
                })
                .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                    const { code } = err.response.data.error;
                    StatusCode(code);
                });
        } else {
            void message.error({
                content: "缺少client_id参数",
                type: "error",
                duration: 1,
                onClose: () => {
                    location.href = document.referrer;
                }
            });
        }
    }, [confirmId]);
    // 判断是外部还是内部
    const typesRole = (appInfo: IAppInfo) => {
        // 外部
        if (Object.keys(appInfo).includes("open") && appInfo.open) {
            isAuthorization();
        } else {
            // 内部
            handleAuthorize(confirmId as string, appInfo);
        }
    };
    // 如果是外部，走一次授权验证，内部直接忽略
    const isAuthorization = () => {
        if (confirmId === null) return;
        oauthAuthinfoApi({ client_id: confirmId })
            .then((response) => {
                if (response.error.code === 0) {
                    handleAuthorize(confirmId, appInfo as IAppInfo);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                if (code === 100022) {
                    // 如果没有授权，进入授权页面
                    history.push({
                        pathname: "/oauth",
                        search: `client_id=${confirmId}${stateValue !== null ? "&state=" + stateValue : ""}${
                            redirectUri !== null ? "&redirect_uri=" + redirectUri : ""
                        }`
                    });
                }
            });
    };
    useEffect(() => {
        if (getToken() !== undefined && appInfo !== undefined) {
            typesRole(appInfo);
        }
    }, [appInfo]);
    const onFinish = (params: { email: string; password: string }) => {
        loginApi({
            type: "base",
            email: params.email,
            password: params.password
        })
            .then((info) => {
                if (info.error.code === 0) {
                    const { data } = info;
                    setEnt(false);
                    setInfoError("");
                    setToken((data as { token: string }).token);
                    if (appInfo !== undefined) {
                        typesRole(appInfo);
                    }
                } else {
                    setInfoError(t("account_message_error"));
                    setEnt(true);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code, msg } = err.response.data.error;
                switch (code) {
                    case 220002:
                        setInfoError(t("account_message"));
                        setEnt(true);
                        break;
                    case 221003:
                        setInfoError(t("error_user"));
                        setEnt(true);
                        break;
                    case 221004:
                        setInfoError(t("user_blocked"));
                        setEnt(true);
                        break;
                    default:
                        setInfoError(msg);
                        setEnt(true);
                        break;
                }
            });
    };
    const handleAuthorize = (clientId: string, info: IAppInfo) => {
        let obj = null;
        if (stateValue !== null) {
            obj = {
                response_type: "code",
                client_id: clientId,
                scope: ["basic_read"],
                state: stateValue
            };
        } else {
            obj = {
                response_type: "code",
                client_id: clientId,
                scope: ["basic_read"]
            };
        }

        oauthAuthorizeApi(obj)
            .then((response) => {
                if (response.error.code === 0) {
                    if (response?.data !== null) {
                        const { code, state } = response.data as ResAuthorizeData;
                        if (redirectUri === null) {
                            void message.error({
                                content: "缺少 redirect_uri 参数",
                                type: "error",
                                duration: 1,
                                onClose: () => {
                                    location.href = document.referrer;
                                }
                            });
                        } else {
                            if (state === "") {
                                window.location.href = `${redirectUri}?code=${code}`;
                            } else {
                                window.location.href = `${redirectUri}?code=${code}&state=${state}`;
                            }
                        }
                        // if (confirmId === null) return;
                        // history.push({ pathname: "/oauth", search: `client_id=${confirmId}`, state: stateValue });
                    }
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code);
            });
    };
    return (
        <>
            {appInfo !== undefined && getToken() === undefined ? (
                <div className={styles.oauthLogin}>
                    <div className={styles.oauthLoginBox}>
                        <div className={styles.header}>
                            <div className={styles.headPhoto}>
                            </div>
                        </div>
                        {ent && (
                            <div className={styles.nav}>
                                <span>{errorInfo}</span>
                                <button
                                    onClick={() => {
                                        setEnt(false);
                                        setInfoError("");
                                    }}>
                                    X
                                </button>
                            </div>
                        )}
                        <div className={styles.main}>
                            <div className={styles.authFormBody}>
                                <div className={styles.authFormBodyHead}>
                                    {appInfo.logo.length > 0 && (
                                        <div className={styles.circleBadge}>
                                            <img src={appInfo.logo.length > 0 ? appInfo.logo : userHead} alt="" />
                                        </div>
                                    )}
                                    <div className={styles.headText}>
                                        <p>
                                            {t("oauthLogin.sign")}
                                            <br />
                                            {t("oauthLogin.continue")}&nbsp;
                                            {/* {appInfo.homepage}
                                            &nbsp; */}
                                            <a href={appInfo.homepage} target="_blank" rel="noreferrer" tabIndex={1}>
                                                {appInfo.name}
                                            </a>
                                        </p>
                                    </div>
                                </div>

                                <div className={styles.form}>
                                    <Form name="normal_login" className="login-form" layout="vertical" autoComplete="off" onFinish={onFinish}>
                                        <Form.Item style={{ marginBottom: 0 }}>
                                            <span className={styles.loginFormForgot}>{t("email")}</span>
                                            <span
                                                className={styles.loginFormForgot}
                                                onClick={() => {
                                                    history.push("/forget");
                                                }}></span>
                                        </Form.Item>
                                        <Form.Item
                                            name="email"
                                            style={{ marginBottom: 10, height: "38px" }}
                                            getValueFromEvent={(e: { target: { value: string } }) => e.target.value.trim()}
                                            rules={[
                                                { required: true, message: t("please_email") },
                                                {
                                                    pattern: EmailRegular(),
                                                    message: t("forget.email_ruels")
                                                }
                                            ]}>
                                            <Input placeholder={t("email")} className={styles.siteFormItemIcon} 
                                            tabIndex={2}
                                             />
                                        </Form.Item>
                                        <Form.Item style={{ margin: 0 }}>
                                            <span className={styles.loginFormForgot}>{t("password")}</span>
                                            {/* 重置密码 */}
                                            <a
                                                tabIndex={6}
                                                href={`${location.origin}/#/forget`}
                                                target="_blank"
                                                rel="noreferrer"
                                                className={styles.loginFormForgot}>
                                                {t("forgot_password")}
                                            </a>
                                        </Form.Item>
                                        <Form.Item
                                            name="password"
                                            style={{ marginBottom: 10, height: "38px" }}
                                            rules={[
                                                { required: true, message: t("please_password") },
                                                {
                                                    pattern: PassWordRegular(),
                                                    message: t("password_verify")
                                                }
                                            ]}>
                                            <Input.Password
                                                type="password"
                                                placeholder={t("password")}
                                                className={styles.siteFormItemIcon}
                                                tabIndex={3}
                                            />
                                        </Form.Item>
                                        <Form.Item style={{ marginTop: "40px", marginBottom: 7 }}>
                                            <Button type="primary" htmlType="submit" className={styles.loginFormButton} 
                                            tabIndex={4}
                                            >
                                                {t("login")}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                            <div className={styles.callout}>
                                <span>
                                    <a
                                        tabIndex={5}
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${location.origin}/#/signup?return_to=oauthLogin&client_id=${confirmId as string}${
                                            stateValue !== null ? "&state=" + stateValue : ""
                                        }${redirectUri !== null ? "&redirect_uri=" + redirectUri : ""}`}
                                        // onClick={() => {
                                        //     if (stateValue !== null) {
                                        //         history.push({
                                        //             pathname: "/signup",
                                        //             search: `return_to=oauthLogin&client_id=${confirmId as string}&state=${stateValue}`
                                        //         });
                                        //     } else {
                                        //         history.push({
                                        //             pathname: "/signup",
                                        //             search: `return_to=oauthLogin&client_id=${confirmId as string}`
                                        //         });
                                        //     }
                                        // }}
                                    >
                                        &nbsp;
                                        {t("oauthLogin.account")}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default OauthLogin;
