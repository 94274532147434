import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./modal.module.less";

export interface DataType {
    handleOk: () => void;
    handleCancel: () => void;
    label: string;
    isModalOpen: boolean;
    title?: string;
}

const MyModal = (props: DataType): JSX.Element => {
    const { handleOk, handleCancel, label = "", isModalOpen, title = "" } = props;
    const { t } = useTranslation();
    return (
        <Modal
            className="myModal"
            title={title}
            open={isModalOpen}
            closable={false}
            onCancel={handleCancel}
            footer={null}
            mask={false}
            focusTriggerAfterClose={false}
            transitionName=""
            maskTransitionName="">
            <div className={styles.content}>{label}</div>
            <div className={styles.myButtonGroup}>
                <div className={styles.myButton} onClick={handleCancel}>
                    {t("modal.no")}
                </div>
                <div className={styles.myButton} onClick={handleOk}>
                    {t("modal.yes")}
                </div>
            </div>
        </Modal>
    );
};

export default MyModal;
