import i18n from "i18next";
import moment from "moment";
// 每个月天数
export const Day = () => {
    const temp = [
        {
            id: `${0}Day`,
            value: "",
            label: `${i18n.t("signup.day")}`
        }
    ];
    for (let i = 1; i <= 31; i++) {
        temp.push({
            id: `${i++}Day`,
            value: (i - 1).toString(),
            label: (i - 1).toString()
        });
        i--;
    }
    return temp;
};
// 12个月
export const Month = () => {
    return [
        {
            id: 0,
            value: "",
            label: `${i18n.t("signup.mouth")}`
        },
        {
            id: 1,
            value: "01",
            label: `${i18n.t("signup.january")}`
        },
        {
            id: 2,
            value: "02",
            label: `${i18n.t("signup.february")}`
        },
        {
            id: 3,
            value: "03",
            label: `${i18n.t("signup.march")}`
        },
        {
            id: 4,
            value: "04",
            label: `${i18n.t("signup.april")}`
        },
        {
            id: 5,
            value: "05",
            label: `${i18n.t("signup.may")}`
        },
        {
            id: 6,
            value: "06",
            label: `${i18n.t("signup.june")}`
        },
        {
            id: 7,
            value: "07",
            label: `${i18n.t("signup.july")}`
        },
        {
            id: 8,
            value: "08",
            label: `${i18n.t("signup.august")}`
        },
        {
            id: 9,
            value: "09",
            label: `${i18n.t("signup.september")}`
        },
        {
            id: 10,
            value: "10",
            label: `${i18n.t("signup.october")}`
        },
        {
            id: 11,
            value: "11",
            label: `${i18n.t("signup.november")}`
        },
        {
            id: 12,
            value: "12",
            label: `${i18n.t("signup.december")}`
        }
    ];
};
// 至今往前100年
export const Year = () => {
    const temp = [
        {
            id: `${0}Year`,
            value: "",
            label: `${i18n.t("signup.year")}`
        }
    ];
    for (let i = 1; i <= 101; i++) {
        temp.push({
            id: `${i++}Year`,
            value: (Number(moment(new Date()).format("YYYY")) + 2 - i).toString(),
            label: (Number(moment(new Date()).format("YYYY")) + 2 - i).toString()
        });
        i--;
    }
    return temp;
};

// 判断日期格式是否正确并且合法
export const validDate = (date: string) => {
    return new Date(date).getDate() === Number(date.substring(date.length - 2));
};

// 判断选择日期，是否在当前日期的17年以内
export const verifyAge = (pitch: string) => {
    // seventeenYears=17年以前的日期
    const seventeenYears = `${Number(moment(new Date()).format("YYYY-MM-DD").split("-")[0]) - 17}-${
        moment(new Date()).format("YYYY-MM-DD").split("-")[1]
    }-${moment(new Date()).format("YYYY-MM-DD").split("-")[2]}`;
    // 当前选择日期和17年前有多少天
    const pitchDay17 = moment(pitch).diff(moment(seventeenYears), "days");
    // 选择日期小于0，就是在17年的相差日期之内，表明不满17岁
    return pitchDay17 <= 0;
};
