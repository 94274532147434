import { Button, Checkbox, Form, Image, Input } from "antd";
import { ClusterOutlined, DesktopOutlined, MailOutlined } from "@ant-design/icons";

import styles from "./login.module.less";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loginApi } from "../../services/login";
import { getToken, setToken } from "../../utils/auth";
import { EmailRegular, PassWordRegular } from "../../utils/regularExpression";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { infoOpen, setInfoRequest, setOpenInfo } from "../../store/render";

const Login = (): JSX.Element => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const openInfo = useAppSelector(infoOpen);

    const [errorInfo, setInfoError] = useState("");
    const [ent, setEnt] = useState(false);

    useEffect(() => {
        if (getToken() === undefined && !openInfo) {
            dispatch(setInfoRequest(undefined));
            dispatch(setOpenInfo(true));
        }
    }, [getToken()]);

    const onFinish = (values: { email: string; password: string }): void => {
        loginApi({
            type: "base",
            email: values.email,
            password: values.password
        })
            .then((info) => {
                if (info.error.code === 0) {
                    const { data } = info;
                    setEnt(false);
                    setToken((data as { token: string }).token);
                    history.push("/settings");
                } else {
                    setInfoError(t("account_message_error"));
                    setEnt(true);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code, msg } = err.response.data.error;
                switch (code) {
                    case 220002:
                        setInfoError(t("account_message"));
                        setEnt(true);
                        break;
                    case 221003:
                        setInfoError(t("error_user"));
                        setEnt(true);
                        break;
                    case 221004:
                        setInfoError(t("user_blocked"));
                        setEnt(true);
                        break;
                    default:
                        setInfoError(msg);
                        setEnt(true);
                        break;
                }
            });
    };

    return (
        <div className={styles.login}>
            <div className={styles.loginBox}>
                <div className={styles.loginLeft}>
                    <div className={styles.logo}>
                        {t("login")}
                    </div>
                    {ent && <div className={styles.error}>{errorInfo}</div>}
                    <div className={styles.iForm}>
                        <Form name="normal_login" className="login-form" initialValues={{ remember: false }} onFinish={onFinish}>
                            <Form.Item
                                name="email"
                                getValueFromEvent={(e: { target: { value: string } }) => e.target.value.trim()}
                                rules={[
                                    { required: true, message: t("please_email") },
                                    {
                                        pattern: EmailRegular(),
                                        message: t("forget.email_ruels")
                                    }
                                ]}>
                                <Input placeholder={t("email")} className={styles.siteFormItemIcon} />
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: "44px" }}
                                name="password"
                                rules={[
                                    { required: true, message: t("please_password") },
                                    {
                                        pattern: PassWordRegular(),
                                        message: t("password_verify")
                                    }
                                ]}
                                // style={{ marginBottom: "3px" }}
                            >
                                <Input.Password type="password" placeholder={t("password")} className={styles.siteFormItemIcon} />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle hidden={true}>
                                    <Checkbox defaultChecked={false} className={styles.loginFormForgot}>
                                        {t("remember_me")}
                                    </Checkbox>
                                </Form.Item>
                                {/* 重置密码 */}
                                <span
                                    className={styles.loginFormForgot}
                                    onClick={() => {
                                        history.push("/forget");
                                    }}>
                                    {t("forgot_password")}
                                </span>
                            </Form.Item>

                            <Form.Item style={{ marginTop: "50px" }}>
                                <div style={{ textAlign: "center" }}>
                                    <Button type="primary" htmlType="submit" className={styles.loginFormButton}>
                                        {t("login")}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>


                    <Button className={styles.footerBut} onClick={() => history.push("/signup")}>
                        {t("create_account")}
                    </Button>
                    {/* <div className={styles.footer}>
                        <div>{t("sign_in_with_Google")}</div>
                        <div>{t("sign_in_with_apple")}</div>
                        <div>{t("sign_in_with_facebook")}</div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
export default Login;
