import { configureStore } from "@reduxjs/toolkit";
import render from "./render";

const store = configureStore({
    reducer: {
        render
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
