const login = {
    email: "Email",
    please_email: "Please type your email!",
    password: "Password",
    please_password: "Please type your password!",
    please_birthday: "Please input your Birthday!",
    remember_me: "Remember me",
    forgot_password: "Forgot password?",
    login: "Login",
    sign_in_with_Google: "Sign in with Google",
    sign_in_with_apple: "Sign in with Apple",
    sign_in_with_facebook: "Sig in with FaceBook",
    create_account: "Create Account",
    email_verify: "Please enter a valid email address",
    password_verify: "Password must be 4 - 16 characters and not contain any invalid characters",
    account_message: "The account name or password is incorrect",
    account_message_error: "invalid account",
    text_one:
        "Join and participate in our community forum. Discuss your favorite games among other fans and interact directly with our game designers!",
    text_two:
        "One account to rule them all! Access your cloud saves and sync your ownership to the community to let others know just how dedicated you are!",
    text_three: "Sign up for our newsletter to stay informed about updates and special offers!",
    title_one: "Community",
    title_two: "Unified Access",
    title_three: "Newsletter",
    title_text: "Join Aquila Club to enjoy the following perks:",
    error_user: "user not found",
    user_blocked: "user blocked",
    birthday: 'Birthday'
};

export default login;
