import { message } from "antd";
import i18n from "i18next";
import { removeToken } from "./auth";

const StatusCode = (val: number) => {
    switch (val) {
        case 220001:
            void message.error({
                content:`${i18n.t("statusCode.invalid_token")}`,
                type: "error",
                duration: 3,
                onClose: () => {
                    removeToken();
                    window.location.href = "#/login";
                }
            });
            break;
        case 220002:
            void message.error(`${i18n.t("statusCode.User_Password_Not_Match")}`);
            break;
        case 220003:
            void message.error(`${i18n.t("statusCode.Create_Token_Failed")}`);
            break;
        case 220004:
            void message.error({
                content:`${i18n.t("statusCode.token_expired")}`,
                type: "error",
                duration: 3,
                onClose: () => {
                    removeToken();
                    window.location.href = "#/login";
                }
            });
            break;
        case 220005:
            void message.error(`${i18n.t("statusCode.permission_check_failed")}`);
            break;
        case 220006:
            void message.error(`${i18n.t("statusCode.permission_deny")}`);
            break;
        case 220007:
            void message.error(`${i18n.t("statusCode.unknown_auth_type")}`);
            break;
        case 221001:
            void message.error(`${i18n.t("statusCode.duplicated_user_email")}`);
            break;
        case 221002:
            void message.error(`${i18n.t("statusCode.add_user_failed")}`);
            break;
        case 221003:
            void message.error(`${i18n.t("statusCode.user_not_found")}`);
            break;
        case 221004:
            void message.error(`${i18n.t("statusCode.user_blocked")}`);
            break;
        case 210004:
            void message.error(`${i18n.t("statusCode.user_email_was_verified")}`);
            break;
        case 210005:
            void message.error(`${i18n.t("statusCode.generate_email_confirm_code_failed")}`);
            break;
        case 210006:
            void message.error(`${i18n.t("statusCode.send_email_failed")}`);
            break;
        case 210007:
            void message.error(`${i18n.t("statusCode.email_confirm_id_not_match")}`);
            break;
        case 210008:
            void message.error(`${i18n.t("statusCode.email_confirm_no_code")}`);
            break;
        case 210009:
            void message.error(`${i18n.t("statusCode.email_confirm_code_not_match")}`);
            break;
        case 210010:
            void message.error(`${i18n.t("statusCode.update_user_info_failed")}`);
            break;
        case 210011:
            void message.error(`${i18n.t("statusCode.user_info_no_update")}`);
            break;
        case 210100:
            void message.error(`${i18n.t("statusCode.can_not_reset_password_before_verify_email")}`);
            break;
        case 210101:
            void message.error(`${i18n.t("statusCode.reset_password_confirm_no_code")}`);
            break;
        case 210102:
            void message.error(`${i18n.t("statusCode.reset_password_code_not_match")}`);
            break;
        case 210201:
            void message.error(`${i18n.t("statusCode.export_user_data_too_frequency")}`);
            break;
        case 210220:
            void message.error(`${i18n.t("statusCode.mark_user_to_delete_failed")}`);
            break;
        case 210221:
            void message.error(`${i18n.t("statusCode.user_not_founded")}`);
            break;
        case 30001:
            void message.error(`${i18n.t("statusCode.cache_get_failed")}`);
            break;
        case 30002:
            void message.error(`${i18n.t("statusCode.cache_decode_failed")}`);
            break;
        case 30003:
            void message.error(`${i18n.t("statusCode.cache_set_failed")}`);
            break;
        case 30004:
            void message.error(`${i18n.t("statusCode.cache_encode_failed")}`);
            break;
        case 30005:
            void message.error(`${i18n.t("statusCode.cache_not_found")}`);
            break;
        case 30006:
            void message.error(`${i18n.t("statusCode.cache_delete")}`);
            break;
        case 30007:
            void message.error(`${i18n.t("statusCode.cache_lock_failed")}`);
            break;
        case 31001:
            void message.error(`${i18n.t("statusCode.send_email_too_frequency")}`);
            break;
        case 1000000:
            void message.error(`${i18n.t("statusCode.unsupported_authorize_response_type")}`);
            break;
        case 1000001:
            void message.error(`${i18n.t("statusCode.auth_app_not_found_for_given_client_id")}`);
            break;
        case 1000010:
            void message.error(`${i18n.t("statusCode.unsupported_grant_type")}`);
            break;
        case 1000011:
            void message.error(`${i18n.t("statusCode.authorization_code_not_found")}`);
            break;
        case 1000012:
            void message.error(`${i18n.t("statusCode.client_id_not_match")}`);
            break;
        case 1000013:
            void message.error(`${i18n.t("statusCode.invalid_client_secret")}`);
            break;
        case 1000014:
            void message.error(`${i18n.t("statusCode.apply_refresh_token_failed")}`);
            break;
        case 1000015:
            void message.error(`${i18n.t("statusCode.apply_access_token_failed")}`);
            break;
        case 1000016:
            void message.error(`${i18n.t("statusCode.check_authorization_code_failed")}`);
            break;
        case 1000100:
            void message.error(`${i18n.t("statusCode.refresh_token_not_found")}`);
            break;
        case 1000101:
            void message.error(`${i18n.t("statusCode.revoke_access_token_failed")}`);
            break;
        case 100020:
            void message.error(`${i18n.t("statusCode.create_oauth_user_failed")}`);
            break;
        case 100021:
            void message.error(`${i18n.t("statusCode.generate_authorize_code_failed")}`);
            break;
        case 1100001:
            void message.error(`${i18n.t("statusCode.invalid_authorization_payload")}`);
            break;
        case 1100002:
            void message.error(`${i18n.t("statusCode.invalid_access_token")}`);
            break;
        case 1100003:
            void message.error(`${i18n.t("statusCode.access_token_not_found")}`);
            break;
        case 500001:
            void message.error(`${i18n.t("statusCode.user_has_been_blocked")}`);
            break;
        case 500002:
            void message.error(`${i18n.t("statusCode.user_block_failed")}`);
            break;
        case 500003:
            void message.error(`${i18n.t("statusCode.user_unblock_failed")}`);
            break;
        case 500100:
            void message.error(`${i18n.t("statusCode.decode_user_failed")}`);
            break;
        case 500101:
            void message.error(`${i18n.t("statusCode.list_user_failed")}`);
            break;
        case 500200:
            void message.error(`${i18n.t("statusCode.duplicated_oauth_app_id")}`);
            break;
        case 500201:
            void message.error(`${i18n.t("statusCode.add_oauth_app_failed")}`);
            break;
        case 500202:
            void message.error(`${i18n.t("statusCode.oauth_app_not_found")}`);
            break;
        case 500203:
            void message.error(`${i18n.t("statusCode.delete_oauth_app_failed")}`);
            break;
        case 500204:
            void message.error(`${i18n.t("statusCode.decode_oauth_app_failed")}`);
            break;
        case 500205:
            void message.error(`${i18n.t("statusCode.list_oauth_app_failed")}`);
            break;
        case 500206:
            void message.error(`${i18n.t("statusCode.oauth_app_not_found_to_delete")}`);
            break;
        case 500220:
            void message.error(`${i18n.t("statusCode.update_oauth_app_info_failed")}`);
            break;
        case 500221:
            void message.error(`${i18n.t("statusCode.oauth_app_info_no_update")}`);
            break;
        case 500300:
            void message.error(`${i18n.t("statusCode.decode_oauth_app_secret_failed")}`);
            break;
        case 500301:
            void message.error(`${i18n.t("statusCode.list_oauth_app_secret_failed")}`);
            break;
        case 500302:
            void message.error(`${i18n.t("statusCode.duplicated_oauth_app_secret_id")}`);
            break;
        case 500303:
            void message.error(`${i18n.t("statusCode.add_oauth_app_secret_failed")}`);
            break;
        case 500304:
            void message.error(`${i18n.t("statusCode.delete_oauth_app_secret_failed")}`);
            break;
        case 500305:
            void message.error(`${i18n.t("statusCode.oauth_app_secret_not_found")}`);
            break;
        default:
            void message.error(`${i18n.t("statusCode.err")}`);
    }
};
export default StatusCode;
