const login = {
    email: "邮箱",
    please_email: "请输入您的电子邮箱!",
    password: "密码",
    please_password: "请输入您的密码!",
    please_birthday: "请输入您的生日!",
    remember_me: "记住密码",
    forgot_password: "忘记密码 / 找回密码?",
    login: "登录",
    sign_in_with_Google: "用谷歌登录",
    sign_in_with_apple: "用Apple登录",
    sign_in_with_facebook: "用Facebook登录",
    create_account: "创建帐号",
    email_verify: "请输入正确的邮件格式",
    password_verify: "密码长度为4 ~ 16个字符之间,且输入了非法字符",
    account_message: "账号或者密码错误",
    account_message_error: "无效账号",
    text_one: "加入并参与我们的社区论坛。在其他粉丝中讨论你最喜欢的游戏，并直接与我们的游戏设计师互动!",
    text_two: "一个账号就能统治所有账号!访问您的云存储并将您的所有权同步到社区，让其他人知道您有多敬业!",
    text_three: "注册我们的通讯，我们一定会让你知道我们的特别优惠!",
    title_one: "社区",
    title_two: "统一接入",
    title_three: "新闻通讯",
    title_text: "加入Aquila Club享受以下福利:",
    error_user: "用户不存在",
    user_blocked: "用户已被冻结",
    birthday: '生日'
};

export default login;
