import React from "react";

interface UserInfo {
    first: string;
    last: string;
    format: string;
}

const UesrName = (props: { userName: UserInfo }) => {
    const { userName } = props;
    return (
        <div>
            {(() => {
                switch (userName.format) {
                    case "{first} {last}":
                        return (
                            <>
                                {userName.first} {userName.last}
                            </>
                        );
                    case "{first} {last}.":
                        return (
                            <>
                                {userName.first} {userName.last}.
                            </>
                        );
                    case "{first}. {last}":
                        return (
                            <>
                                {userName.first}. {userName.last}
                            </>
                        );
                    case "{first}{last}":
                        return (
                            <>
                                {userName.first}
                                {userName.last}
                            </>
                        );
                    case "{first}":
                        return <>{userName.first}</>;
                    case "{last} {first}":
                        return (
                            <>
                                {userName.last} {userName.first}
                            </>
                        );
                    case "{last}{first}":
                        return (
                            <>
                                {userName.last}
                                {userName.first}
                            </>
                        );
                    case "{last}. {first}":
                        return (
                            <>
                                {userName.last}. {userName.first}
                            </>
                        );
                    case "{last}":
                        return <>{userName.last}</>;
                    default:
                        return (
                            <>
                                {userName.first}
                                {userName.last}
                            </>
                        );
                }
            })()}
        </div>
    );
};
export default UesrName;
