export default {
    company: "公司",
    about: "关于",
    careers:"职业生涯",
    games: "游戏",
    community: "社区",
    legal: "法律",
    privacy_policy: "隐私政策",
    terms_of_use: "使用条款",
    contact: "联系人",
    business_inquiry: "业务咨询",
    press_media: "新闻/媒体",
    Trademarks: "© Aquila 互动有限公司。版权所有。商标均属于其各自的所有者。",
    settings: "设置",
    logOut: "退出登录",
    login: "登录"
}