export default {
    "oauthLogin.sign": "登录到",
    "oauthLogin.continue": "在继续 ",
    "oauthLogin.NewToAquila": "新Aquila吗?",
    "oauthLogin.account": "创建账户。",
    "oauthLogin.Terms": "条款",
    "oauthLogin.Privacy": "隐私",
    "oauthLogin.Security": "安全",
    "oauthLogin.ContactAquila": "联系 Aquila"
};
