import http from "../utils/request";
// 发送确认邮件
export const emailSendApi = async <T>() => {
  return await http<T>({
    url: "/user/email/send",
    method: "post",
    data: {}
  });
};

// 确认邮件
export const emailConfirmApi = async <T>(data: IAPI.Confirm) => {
  return await http<T>({
    url: "/user/email/confirm",
    method: "post",
    data: { ...data },
  });
};
