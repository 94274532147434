import React, { useState } from "react";
import { Select } from "antd";
import styles from "./index.module.less";
import { Day, Month, Year } from "../../utils/time";

const { Option } = Select;

type Date = string;

interface DateValue {
    year: Date;
    month: Date;
    day: Date;
}

interface DateInputProps {
    value?: DateValue;
    onChange?: (value: DateValue) => void;
}
const MyDatePicker: React.FC<DateInputProps> = ({ value = {}, onChange }) => {
    const [day, setDay] = useState<Date>("");
    const [month, setMonth] = useState<Date>("");
    const [year, setYear] = useState<Date>("");

    const triggerChange = (changedValue: { day?: Date; month?: Date; year?: Date }) => {
        onChange?.({ day, month, year, ...value, ...changedValue });
    };

    const onDayChange = (newDay: Date) => {
        if (!("day" in value)) {
            setDay(newDay);
        }
        triggerChange({ day: newDay });
    };
    const onMonthChange = (newMonth: Date) => {
        if (!("month" in value)) {
            setMonth(newMonth);
        }
        triggerChange({ month: newMonth });
    };
    const onYearChange = (newYear: Date) => {
        if (!("year" in value)) {
            setYear(newYear);
        }
        triggerChange({ year: newYear });
    };

    return (
        <div className={styles.birthdayContent}>
            <Select value={value.day ?? day} onChange={onDayChange}>
                {Day().map((item) => {
                    return (
                        <Option key={item.id} value={item.value}>
                            {item.label}
                        </Option>
                    );
                })}
            </Select>
            <Select value={value.month ?? month} style={{ margin: "0 8px" }} onChange={onMonthChange}>
                {Month().map((item) => {
                    return (
                        <Option key={item.id} value={item.value}>
                            {item.label}
                        </Option>
                    );
                })}
            </Select>
            <Select value={value.year ?? year} onChange={onYearChange}>
                {Year().map((item) => {
                    return (
                        <Option key={item.id} value={item.value}>
                            {item.label}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

export default MyDatePicker;
