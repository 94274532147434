export default {
    company: "Company",
    about: "About",
    careers: "Careers",
    games: "Games",
    community: "Community",
    legal: "Legal",
    privacy_policy: "Privacy Policy",
    terms_of_use: "Terms of Use",
    contact: "Contact",
    business_inquiry: "Business Inquiries",
    press_media: "Press / Media",
    Trademarks: "© Aquila Interactive Co, Limited. All rights reserved. Trademarks belong to their respective owners.",
    settings: "Settings",
    logOut: "Log Out",
    login: "Log In"
};
