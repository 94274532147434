import { Button, Image, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { emailConfirmApi } from "../../services/email";
import { getQueryString } from "../../utils/getQueryString";
import styles from "./index.module.less";

export default function index() {
    const { t } = useTranslation();
    const history = useHistory();
    const [spinning, setSpinning] = useState(true);

    
    useEffect(() => {
        const confirmId: string | null = getQueryString("confirm_id");
        const code = getQueryString("code");
        // 自验证参数
        // const confirmId: string | null = "123364818924361cb0485f717a82fec9";
        // const code = "ebf83f64-e19d-4307-9834-a7702b35cfe8";
        if (confirmId !== null && code !== null) {
            const accounts: { confirm_id: string; code: string } = {
                confirm_id: confirmId,
                code
            };
            emailConfirmApi(accounts)
                .then((info) => {
                    if (info.error.code === 0) {
                        setTimeout(() => {
                            setSpinning(true);
                            history.push("/settings");
                        }, 3000);
                    } else {
                        setTimeout(()=> {
                            setSpinning(false);
                        },3000)
                    }
                })
                .catch((e: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } } | string) => {
                    if (e instanceof String) {
                        console.log(e);
                    } else {
                        setTimeout(()=> {
                            setSpinning(false);
                        },3000)
                    }
                });
        } else {
            setTimeout(()=> {
                setSpinning(false);
            },3000)
        }
    }, []);

    return (
        <div className={styles.accounts}>
            <div className={styles.accountsLeft}>
                
            </div>
            <Spin spinning={spinning} size="large">
                <div className={styles.accountsRight}>
                    <span>
                        {!spinning && <span>{t("accounts.confirmed")} </span>}
                        <br />
                        <br />
                        {spinning && <p>{t("accounts.account")}</p>}
                        {!spinning && <p>{t("accounts.invalid")}</p>}
                    </span>
                    <Button className={styles.btn} onClick={() => history.push("/login")}>
                    {t("accounts.settings")}
                    </Button>
                </div>
            </Spin>
        </div>
    );
}
