const settings = {
    // index
    user_list: "Failed to load the user list. Procedure",
    error: "Please try again if you fail",
    // Account
    data: "Data",
    data_account: "Data Account",
    here_you_can_request: "Here you can request to export the raw data we have on you or permanently delete your user account.",
    are_you_sure: "Are you sure you would like to permanently delete your account? Please note this is an irreversible process.",
    show_option: "Show options",
    data_request: "Data Request",
    cancel: "Cancel",
    delete_account: " Delete Account",
    delete_success: "Delete Success",
    delete_err: "Delete Err",
    back: "Back",
    email_send: "The data is being exported, please check in the email later",
    export_title: "Data Request",
    export_content: "You will export the data, the data summary will be sent to your email, please note that check. The export interval is one hour.",
    // Information
    email: "Email",
    password: "Password",
    birthday: "Birthday",
    change_password: "Change Password",
    VERIFIED: "VERIFIED",
    UNVERIFIED: "UNVERIFIED",
    email_send_success: "Email Sent Successfully",
    email_send_warning: "The Email is sent too frequently. Please try again later",
    email_send_error: "Failed to send email. Please try again",
    verified_email: "Verified Email",
    click_verified_email: "Resend Confirmation",
    // Newsletter
    newsletter: "Newsletter",
    you_are_subscribed: "You are subscribed to our newsletter! We will notify you of new releases and occasional special deals!",
    looks_like_you:
        "Looks like you have not opted to receive our newsletter. Join our newsletter today to receive news about our games and occasional special offers!",
    subscribe: "Subscribe",
    unsubscribe: "Unsubscribe",
    unsubscribe_success: "Unsubscribe Success",
    unsubscribe_err: "Unsubscribe Err",
    subscribe_success: "The subscription confirmation email was sent successfully. Please check your inbox.",
    subscribe_err: "Failed to send the subscription email",
    subscription: "We will send you an email with a link to confirm your email address. please click the link to confirm your subscription.",
    un_subscription: "You are about to cancel your subscription and will no longer receive news and events from Aquila Club.",
    // Ownership
    ownership: "Ownership",
    link_your: "Link your steam account to see your game library and unlock ownership badges for the forum!",
    link_your_steam_account: "Link your Steam account",
    unlink: "Unlink",

    // EditProfile
    user_name: "User Name",
    edit_profile: " Edit Profile",
    name: "First Name",
    input_name: "Please Input First Name",
    user: "Last Name",
    input_user: "Please input Last Name",
    name_type: "User name display format",
    input_name_type: "Please Select",
    save_personal_information: "Save Profile",
    edit_success: "Edit Success",
    edit_Err: "Edit Error",
    first_last: "<firstName> <lastName>",
    last_first: "<lastName> <firstName>",

    // reactCropper
    click_modify_avatar: "Click Modify Avatar",
    edit_image: "Crop your new profile picture",
    Set_new_profile_picture: "Set new profile picture",
    img_err: "We only support PNG or JPG pictures.",
    edit: "Edit"
};

export default settings;
