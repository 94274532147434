import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FileImageOutlined } from "@ant-design/icons";
import styles from "./settings.module.less";
import { steamInfo, steamLink, steamLinkUrl, steamUnLink } from "../../services/steam_bind";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { infoRequest, getInfoRequest } from "../../store/render";
import StatusCode from "../../utils/statusCode";
import { message, Popover } from "antd";
import steam from "../../assets/steam/steam.png";
import axios from "axios";

export interface AppOwnerships {
    appid: number;
    ownersteamid: string;
    ownsapp: boolean;
    permanent: boolean;
    sitelicense: boolean;
    timedtrial: boolean;
    timestamp: string;
}
export interface SteamInfo {
    app_ownerships: AppOwnerships[];
    player_summary: {
        avatar: string;
        avatarfull: string;
        avatarmedium: string;
        personaname: string;
        profileurl: string;
        steamid: string;
        timecreated: number;
    };

    error: Error;
}
interface Params {
    [K: string]: string;
}
interface SteamInfoVal {
    avatar: string;
    personaname: string;
    profileurl: string;
}
interface SteamVal {
    name: string;
    headerImage: string;
    steamAppid: string;
}

const Ownership: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [steamVal, setSteamVal] = React.useState<SteamVal[]>([
        {
            name: "",
            headerImage: "",
            steamAppid: ""
        }
    ]);

    const [steamInfoVal, setSteamInfoVal] = React.useState<SteamInfoVal>({
        avatar: "",
        personaname: "",
        profileurl: ""
    });
    const [steamBindFlag, setSteamBindFlag] = React.useState<boolean>(true);
    const renderInfo = useAppSelector(infoRequest);
    useEffect(() => {
        if (renderInfo?.links !== null && renderInfo?.links[0].type === "steam") {
            getSteamInfo();
        }
    }, []);
    useEffect(() => {
        const url = window.location.href;
        if (renderInfo?.links !== null) {
            renderInfo?.links.forEach((item) => {
                if (item.type === "steam") {
                    setSteamBindFlag(false);
                }
            });
        } else {
            setSteamBindFlag(true);
        }
        if (url.includes("?")) {
            const obj: Params = {};
            const params = url.slice(url.indexOf("?") + 1, url.indexOf("#")).split("&");
            params.forEach((item) => {
                const arr = item.split("=");
                if (arr[1].includes("%")) {
                    arr[1] = decodeURIComponent(arr[1]);
                }
                obj[arr[0]] = arr[1];
            });
            // 绑定
            void steamLink({ params: obj })
                .then((info) => {
                    const { error } = info;
                    if (error.code === 0) {
                        setSteamBindFlag(false);
                        void message.success(t("steamBind.steam_link_success"));
                        void dispatch(getInfoRequest());
                        setTimeout(() => {
                            if (window.location.href.split("?").length > 1) {
                                const startIdnex = window.location.href.indexOf("?");
                                const endIdnex = window.location.href.indexOf("#");
                                const deleteParams = window.location.href.slice(startIdnex, endIdnex);
                                // 刷新页面
                                window.location.href = window.location.href.replace(deleteParams, "");
                            }
                        }, 2000);
                    } else {
                        StatusCode(error.code);
                    }
                })
                .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                    const { code } = err.response.data.error;
                    StatusCode(code);
                });
        }
    }, [window.location.href]);

    // 绑定
    const steamBindding = () => {
        void steamLinkUrl({ return_path: "" })
            .then((info) => {
                const { data, error } = info;
                if (error.code === 0) {
                    const url = (data as { url: string }).url;
                    window.location.href = url;
                } else {
                    StatusCode(error.code);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code);
            });
    };

    // 解除绑定
    const relieveBinding = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        void steamUnLink()
            .then((info) => {
                const { error } = info;
                if (error.code === 0) {
                    void message.success(t("steamBind.steam_unlink_success"));
                    setSteamBindFlag(true);
                    void dispatch(getInfoRequest());
                } else {
                    StatusCode(error.code);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code);
            });
    };

    // 获取游戏信息
    const getSteamInfo = () => {
        void steamInfo().then((response) => {
            if (response.error.code === 0) {
                const data = response.data as SteamInfo;
                const playerSummary = data.player_summary;
                setSteamInfoVal({
                    avatar: playerSummary.avatar,
                    personaname: playerSummary.personaname,
                    profileurl: playerSummary.profileurl
                });
                const appOwnerships = data.app_ownerships;
                const temp = appOwnerships.map((item) => item.appid);
                const urlArr = temp.map(async (item) => {
                    return await axios.get(`/steam/appdetails/${item}.json`);
                });
                void axios.all(urlArr).then((res) => {
                    if (res.length > 0) {
                        const temp = res.map((item) => {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            const { name, header_image, steam_appid } = item.data as { name: string; header_image: string; steam_appid: number };

                            return {
                                name,
                                headerImage: header_image,
                                steamAppid: steam_appid.toString()
                            };
                        });
                        setSteamVal(temp);
                    }
                });
            }
        });
    };
    const handleSteamGameLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, steamAppid: string) => {
        e.stopPropagation();
        window.open(`https://store.steampowered.com/app/${steamAppid}`);
    };
    // 弹窗内容
    const content = (
        <div className={styles.steamContent}>
            {!steamBindFlag &&
                steamVal.map((item, index) => {
                    return (
                        <div key={index} className={styles.steamGameContent} onClick={(e) => handleSteamGameLink(e, item.steamAppid)}>
                            <div>
                                <img src={item.headerImage} alt="" />
                            </div>
                            <div className={styles.steamGameTitleContent}>{item.name}</div>
                        </div>
                    );
                })}
        </div>
    );
    // 新打开网页
    const handleProfileUrl = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, val: string) => {
        e.stopPropagation();
        if (val !== "") {
            window.open(val);
        }
    };
    return (
        <>
            <div className={styles.ownershipContent}>
                <div className={styles.ownershipTitle}>{t("settings.ownership")}</div>
                <div className={styles.ownershipIcon} style={{ color: "#fff" }}>
                    <FileImageOutlined />
                </div>

                {steamBindFlag ? (
                    <div>
                        <div className={styles.newSletterText}>{t("settings.link_your")}</div>
                        <div
                            className={styles.ownershipButton}
                            onClick={() => steamBindding()}
                            style={{
                                background: "rgb(28, 28, 30)"
                            }}>
                            <div className={styles.ownershipButtonIcon}>
                                <span>
                                    <img src={steam} alt="" />
                                </span>
                            </div>
                            <div className={styles.ownershipButtonText}>{t("settings.link_your_steam_account")}</div>
                        </div>
                    </div>
                ) : steamVal[0].name !== "" ? (
                    <Popover getPopupContainer={(trigger) => trigger} overlayClassName={styles.antPopover} content={content} placement="bottom">
                        <div
                            className={styles.ownershipButton2}
                            style={{
                                background: "rgb(28, 28, 30)"
                            }}>
                            <div className={styles.ownershipButtonIcon}>
                                <span>
                                    <img src={steam} alt="" />
                                </span>
                            </div>
                            <div className={styles.steamUser} onClick={(e) => handleProfileUrl(e, steamInfoVal.profileurl)}>
                                {steamInfoVal.avatar !== "" && <img src={steamInfoVal.avatar} alt="" />}
                                &nbsp;
                                {steamInfoVal.personaname !== "" ? steamInfoVal.personaname : t("steamBind.steam_linked")}
                            </div>
                            <div className={styles.goBack} onClick={(e) => relieveBinding(e)}>
                                {t("settings.unlink")}
                            </div>
                        </div>
                    </Popover>
                ) : (
                    <div
                        className={styles.ownershipButton2}
                        style={{
                            background: "rgb(28, 28, 30)"
                        }}>
                        <div className={styles.ownershipButtonIcon}>
                            <span>
                                <img src={steam} alt="" />
                            </span>
                        </div>
                        <div className={styles.steamUser} onClick={(e) => handleProfileUrl(e, steamInfoVal.profileurl)}>
                            {steamInfoVal.avatar !== "" && <img src={steamInfoVal.avatar} alt="" />}
                            &nbsp;
                            {steamInfoVal.personaname !== "" ? steamInfoVal.personaname : t("steamBind.steam_linked")}
                        </div>
                        <div className={styles.goBack} onClick={(e) => relieveBinding(e)}>
                            {t("settings.unlink")}
                        </div>
                    </div>
                )}

                {/* 展示列表 */}
                {/* <div className={styles.steam}>
                    {!steamBindFlag &&
                        steamVal.map((item) => {
                            return (
                                <div key={item.headerImage} className={styles.steamGame}>
                                    <div>
                                        <img src={item.headerImage} alt="" />
                                    </div>
                                    <div className={styles.steamGameTitle}>{item.name}</div>
                                </div>
                            );
                        })}
                </div> */}
            </div>
        </>
    );
};
export default Ownership;
