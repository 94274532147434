import { IResponse } from "../utils/base_model";
import http from "../utils/request";

// 获取openidde的登录地址
export const steamLinkUrl = async <T>(data: IAPI.SteamLinkUrl) => {
    return await http<T>({
        url: "/user/link/steam/getlinkurl",
        method: "post",
        data
    });
};
// 绑定
export const steamLink = async <T>(data: IAPI.SteamLink) => {
    return await http<T>({
        url: "/user/link/steam/link",
        method: "post",
        data
    });
};
// 取消绑定
export const steamUnLink = async <T>(): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/link/steam/unlink",
        method: "post",
        data: {}
    });
};
// 获取Steam用户信息
export const steamInfo = async <T>(): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/link/steam/info",
        method: "post",
        data: {}
    });
};
