import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import UesrName from "../../components/userName";
import { useAppDispatch } from "../../hooks";
import { emailSendApi } from "../../services/email";
import { getInfoRequest } from "../../store/render";
import StatusCode from "../../utils/statusCode";
import styles from "./settings.module.less";

export interface DataType {
    email: string;
    emailVerified: boolean;
    subscribeNews?: boolean;
    name: {
        first: string;
        last: string;
        format: string;
    };
    birthday: string;
}
export interface propsType {
    userInfo: DataType;
}
const Information = (props: propsType) => {
    const { email, emailVerified, name, birthday } = props.userInfo;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const handleSend = () => {
        emailSendApi()
            .then((response) => {
                if ((response.error as { code: number }).code === 0) {
                    void message.success(`${t("settings.email_send_success")}`);
                }
            })
            .catch(async (err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                if (code === 210004) {
                    await dispatch(getInfoRequest());
                    return;
                }
                StatusCode(code);
            });
    };
    const handleReset = () => {
        history.push("/changePwd");
    };
    return (
        <>
            <div className={styles.title} style={{ paddingTop: "20px" }}>
                <p className={styles.titleInfo}>{t("settings.user_name")}</p>
                <div>
                    <UesrName userName={name} />
                </div>
            </div>
            {birthday !== "" && (
                <div className={styles.title}>
                    <p className={styles.titleInfo}> {t("settings.birthday")}</p>
                    <p>{birthday}</p>
                </div>
            )}
            <div className={styles.title}>
                <p className={styles.titleInfo}> {t("settings.email")}</p>
                <p>
                    {email}
                    {emailVerified ? (
                        <span className={styles.titleInfoVERIFIED}>
                            <CheckCircleOutlined />
                            {t("settings.VERIFIED")}
                        </span>
                    ) : (
                        <span className={styles.titleInfoUNVERIFIED}>
                            <ExclamationCircleOutlined />
                            {t("settings.UNVERIFIED")}
                        </span>
                    )}
                </p>
            </div>
            {!emailVerified && (
                <div className={styles.title}>
                    <p className={styles.titleInfo}>{t("settings.verified_email")}</p>
                    <div className={styles.changePassword} onClick={handleSend}>
                        {t("settings.click_verified_email")}
                    </div>
                </div>
            )}

            <div className={styles.title}>
                <p className={styles.titleInfo}> {t("settings.password")}</p>
                <div className={styles.changePassword} onClick={handleReset}>
                    {t("settings.change_password")}
                </div>
            </div>
        </>
    );
};
export default Information;
