import axios from "axios";
import type { AxiosResponse, AxiosRequestConfig } from "axios";
import i18n from "i18next";
import { message } from "antd";
import { getToken, removeToken } from "./auth";
import { IResponse } from "./base_model";

const instance = axios.create({
    timeout: 10000,
    // baseURL: "http://10.10.81.188:8085/usercenter" // 本地
    baseURL: "https://api.accounts.zhifou.ai/usercenter" // 线上
});

// baseURL: "https://api.aquilainteractive.io/usercenter"
// 不需要token的白名单
const whiteList = ["/user/signin", "/user/signup", "/user/resetpwd", "/user/resetpwdconfirm","/oauth/appinfo"];
// 请求拦截
instance.interceptors.request.use(
    (config) => {
        // 获取存储的token
        const token = getToken();
        // 判断token是否存在以及是否是登录
        if (token === undefined && !whiteList.includes(config.url as string)) {
            void message.error({
                content: `${i18n.t("request.user_not_login")}`,
                type: "error",
                duration: 3,
                onClose: () => {
                    // 在这个时候token其实已经没了，防止死灰复燃在把token清理一下
                    removeToken();
                    window.location.href = "#/login";
                }
            });
        } else if (token !== undefined) {
            // token存在时把他写入请求头
            config.headers = {
                "x-aquila-token": token,
                ...config.headers
            };
            return config;
        } else {
            return config;
        }
    },
    async (error) => {
        console.log(error);
        
        return await Promise.reject(error);
    }
);

// 响应拦截
instance.interceptors.response.use(
    (response: { data: { error: { code: number; msg: string }; data: unknown } }) => {
        if (Boolean(response.data) && Boolean(response.data.error)) {            
            if (response.data.error.code === 220001) {
                void message.error({
                    content: response.data.error.msg,
                    type: "error",
                    duration: 2,
                    onClose: () => {
                        removeToken();
                        window.location.href = "#/login";
                    }
                });
                throw new Error(response.data.error.msg);
            } else if (response.data.error.code === 220004) {
                void message.error({
                    content: response.data.error.msg,
                    type: "error",
                    duration: 2,
                    onClose: () => {
                        removeToken();
                        window.location.href = "#/login";
                    }
                });
                return response;
            } else {
                return response;
            }
        } else {
            return response;
        }
    }
    // async (error: { response: { status: unknown } }) => {
    //     if (Boolean(error) && Boolean(error.response)) {
    //         message.destroy();
    //         switch (error.response.status) {
    //             case 400:
    //                 void message.error(`${i18n.t("request.bad_request")}`);
    //                 break;
    //             case 401:
    //                 void message.info({
    //                     content: `${i18n.t("request.login_date")}`,
    //                     type: "info",
    //                     duration: 3,
    //                     onClose: () => {
    //                         removeToken();
    //                         window.location.href = "#/login";
    //                     }
    //                 });
    //                 break;
    //             case 403:
    //                 // void message.info("拒绝访问");
    //                 break;
    //             case 404:
    //                 void message.info(`${i18n.t("request.not_found")}`);
    //                 break;
    //             case 405:
    //                 void message.info(`${i18n.t("request.no_allowance")}`);
    //                 break;
    //             case 408:
    //                 void message.info(`${i18n.t("request.request_timeout")}`);
    //                 break;
    //             case 500:
    //                 void message.info(`${i18n.t("request.server_error")}`);
    //                 break;
    //             case 501:
    //                 void message.info(`${i18n.t("request.network_not_implemented")}`);
    //                 break;
    //             case 502:
    //                 void message.info(`${i18n.t("request.network_error")}`);
    //                 break;
    //             case 503:
    //                 void message.info(`${i18n.t("request.service_unavailable")}`);
    //                 break;
    //             case 504:
    //                 void message.info(`${i18n.t("request.network_timeout")}`);
    //                 break;
    //             case 505:
    //                 void message.info(`${i18n.t("request.version_does")}`);
    //                 break;
    //             default:
    //                 void message.info(`${i18n.t("request.connection_error")}${error.response.status as string}`);
    //         }
    //     }
    //     return await Promise.reject(error);
    // }
);

export default async function http<T = Record<string, unknown>>(config: AxiosRequestConfig): Promise<IResponse<T>> {
    const res: AxiosResponse<IResponse<T>> = await instance.request(config);
    return res.data;
}
