export default {
  // forget
  forget_title:"忘记你的密码了？",
  forget_message:"输入您用于注册的电子邮件地址，我们将向您发送有关如何重置密码的说明。",
  forget_submitted_title:"收到重置密码的请求",
  forget_submitted_message:"请检查您的收件箱以获取确认电子邮件，并按照链接重置密码。",
  forget_form_placeholder:"你的邮箱地址",
  forget_sendemail_succes:"邮件发送成功",
  email_ruels:"邮件格式不正确",

  // reset
  reset_title:"确认你的新密码",
  reset_submitted_title:"您的密码已设置",
  confirm_id:"请输入确认ID",
  confirm_code:"请输入确认码",
  new_password:"新的密码",
  reset_succee:"密码重置成功",
  getConfirm_fail:"确认信息获取失败",
  require_msg:"密码不能为空",
  password_validate_msg:"密码包含4到16个字符",

  back_to_login:"返回登录",
  submit:"提交",
  welcome:"欢迎来到AQUILA"
};