import { Button, Image, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { subscribeconfirm } from "../../services/user";
import styles from "./index.module.less";

export default function index() {
    const history = useHistory();
    const [spinning, setSpinning] = useState(true);
    const { t } = useTranslation();
    function getQueryString(name: string) {
        const urlString = location.href;
        // 自验证参数
        // const urlString = "http://localhost:3000/login#/accounts?confirm_id=参数&code=参数";
        const params = urlString.split("?")[1];
        if (params !== undefined) {
            const queryString = params; // window.location.search
            if (queryString.length === 0) return null; // 如果无参，返回null
            const re = /[?&]?([^=]+)=([^&]*)/g;
            let tokens;
            while ((tokens = re.exec(queryString)) != null) {
                if (decodeURIComponent(tokens[1]) === name) {
                    return decodeURIComponent(tokens[2]);
                }
            }
            return null;
        } else {
            return null;
        }
    }
    useEffect(() => {
        const confirmId: string | null = getQueryString("confirm_id");
        const code = getQueryString("code");
        // 自验证参数
        // const confirmId: string | null = "123364818924361cb0485f717a82fec9";
        // const code = "ebf83f64-e19d-4307-9834-a7702b35cfe8";
        if (confirmId !== null && code !== null) {
            const accounts: { confirm_id: string; code: string } = {
                confirm_id: confirmId,
                code
            };
            subscribeconfirm(accounts)
                .then((info) => {
                    if (info.error.code === 0) {
                        setTimeout(() => {
                            setSpinning(true);
                            history.push("/settings");
                        }, 3000);
                    } else {
                        setTimeout(() => {
                            setSpinning(false);
                        }, 3000);
                    }
                })
                .catch((e: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } } | string) => {
                    if (e instanceof String) {
                        console.log(e);
                    } else {
                        setTimeout(() => {
                            setSpinning(false);
                        }, 3000);
                    }
                });
        } else {
            setTimeout(() => {
                setSpinning(false);
            }, 3000);
        }
    }, []);

    return (
        <div className={styles.accounts}>
            <div className={styles.accountsLeft}>
                
            </div>
            <Spin spinning={spinning} size="large">
                <div className={styles.accountsRight}>
                    <span>
                        {!spinning && <span>{t("subscribe.your_account")}</span>}
                        <br />
                        <br />
                        {spinning && <p>{t("subscribe.wait_while")}</p>}
                        {!spinning && <p>{t("subscribe.the_code")}</p>}
                    </span>
                    <Button className={styles.btn} onClick={() => history.push("/settings")}>
                        {t("subscribe.back_settings")}
                    </Button>
                </div>
            </Spin>
        </div>
    );
}
