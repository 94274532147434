import { IResponse } from "../utils/base_model";
import http from "../utils/request";

// 登录
export const loginApi = async <T>(data: IAPI.Login): Promise<IResponse<T>> => {
  return await http<T>({
    url: "/user/signin",
    method: "post",
    data: { ...data },
  });
};

// 注册
export const signupApi = async <T>(data: IAPI.Signup) => {
  return await http<T>({
    url: "/user/signup",
    method: "post",
    data: { ...data },
  });
};

// 退出登录
export const logoutApi = async <T>(): Promise<IResponse<T>> => {
  return await http<T>({
    url: "/user/logout",
    method: "post",
    data: {}
  });
};

