import { IResponse } from "../utils/base_model";
import http from "../utils/request";

// 用户信息
export const infoApi = async <T>(): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/info",
        method: "post",
        data: {}
    });
};

// 用户信息更新
export const updateApi = async <T>(data: IAPI.Updata): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/update",
        method: "post",
        data: { ...data }
    });
};

// 删除账号
export const deleteApi = async <T>(): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/delete",
        method: "post",
        data: {}
    });
};

// 重置密码（发信）
export const resetpwdApi = async <T>(data: IAPI.Resetpwd): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/resetpwd",
        method: "post",
        data: { ...data }
    });
};

// 重置密码（确认）
export const resetpwdconfirmApi = async <T>(data: IAPI.Resetpwdconfirm): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/resetpwdconfirm",
        method: "post",
        data: { ...data }
    });
};
// 修改密码
export const changePwdApi = async <T>(data: IAPI.Changepwd) => {
    return await http<T>({
        url: "/user/changepwd",
        method: "post",
        data: { ...data }
    });
};
// 订阅（发信）
export const subscribe = async <T>() => {
    return await http<T>({
        url: "/user/subscribe",
        method: "post",
        data: {}
    });
};

// 订阅（确认）
export const subscribeconfirm = async <T>(data: IAPI.ResetSubscribeConfirm) => {
    return await http<T>({
        url: "/user/subscribeconfirm",
        method: "post",
        data: { ...data }
    });
};
// 订阅（取消）
export const unsubscribe = async <T>() => {
    return await http<T>({
        url: "/user/unsubscribe",
        method: "post",
        data: {}
    });
};

// 数据导出
export const dataExportApi = async <T>() => {
    return await http<T>({
        url: "/user/data/export",
        method: "post",
        data: {}
    });
};

// 用户头像-获取上传地址
export const getavataruploadurlApi = async <T>() => {
    return await http<T>({
        url: "/user/getavataruploadurl",
        method: "post",
        data: {}
    });
};

// 用户头像-获取上传地址
export const commitavatarApi = async <T>(data:{session: string, file_sum: string}) => {
    return await http<T>({
        url: "/user/commitavatar",
        method: "post",
        data
    });
};
