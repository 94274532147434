import React from "react";
import "./App.css";
import "./locales";
import RouterLink from "./router";
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
const App: React.FC = () => {
    return (
        <div className="App">
            <header className="App-header">
                <Provider store={store}>
                    <Router>
                        <RouterLink />
                    </Router>
                </Provider>
            </header>
        </div>
    );
};

export default App;
