import http from "../utils/request";

// 获取OAuth应用信息
export const oauthAppinfoApi = async <T>(data: IAPI.OauthList) => {
    return await http<T>({
        url: "/oauth/appinfo",
        method: "post",
        data
    });
};
// 授权
export const oauthAuthorizeApi = async <T>(data: IAPI.Authorize | IAPI.AuthorizeState) => {
    return await http<T>({
        url: "/oauth/authorize",
        method: "post",
        data
    });
};
// 授权信息
export const oauthAuthinfoApi = async <T>(data: IAPI.OauthList) => {
    return await http<T>({
        url: "/oauth/authinfo",
        method: "post",
        data
    });
};

