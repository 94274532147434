export default {
    "oauthLogin.sign": "Sign in",
    "oauthLogin.continue": "to continue to",
    "oauthLogin.NewToAquila": "New to Aquila?",
    "oauthLogin.account": "Create an account.",
    "oauthLogin.Terms": "Terms",
    "oauthLogin.Privacy": "Privacy",
    "oauthLogin.Security": "Security",
    "oauthLogin.ContactAquila": "Contact Aquila"
};
