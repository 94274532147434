export default {
  title:"修改密码",
  old_password:"旧密码",
  new_password:"新密码",
  submitted_msg:"密码修改成功",
  require_msg1:"请输入旧密码",
  require_msg2:"请输入新密码",
  same_pwd:"旧新密码一样",
  error_220002:"用户密码不匹配",
  modify_result:"修改成功",
  return_page:"3秒后返回settings页面",
  back:"返回"
};