import React, { useState,Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input,message } from "antd";
import { useTranslation } from "react-i18next";
import { changePwdApi } from "../../services/user";
import StatusCode from "../../utils/statusCode";

import styles from "./reset.module.less";
import { PassWordRegular } from "../../utils/regularExpression";

interface Resetinfo {
    current:string,
    newm:string
}

const ChangePwd: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [resultFlag, setresultFlag] = useState<boolean>(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailLayout = {
        wrapperCol: { offset: 0, span: 16 }
    };

    const onFinish = (values: Resetinfo) => {
        const { current,newm } = values
        if(current === newm){
            void message.error(t("changePwd.same_pwd"),3)
        }else{
            changePwdApi({
                current:values.current,
                new:values.newm
            }).then(info=>{
                const {error}=info;
                if(error.code === 0){
                    setresultFlag(true)
                    void message.success(t("changePwd.modify_result"));
                    setTimeout(() => {
                        history.push('/settings')
                    }, 3000);
                }
                console.log(info);
            }).catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code)
            });
        }
    };

    const backSettings = () => {
        history.push('/settings')
    };
    return (
        <>
            <div className={styles.container}>
                <div className={styles.box}>
                    <div className={styles.formBox}>
                        {resultFlag ? (
                            <>
                                <p>{t("changePwd.submitted_msg")}</p>
                                <p>{t("changePwd.return_page")}</p>
                            </>
                        ) : (
                            <>
                                <p>{t("changePwd.title")}</p>
                                <Form {...layout} name="nest-messages" onFinish={onFinish}>
                                    <Form.Item
                                        name="current"
                                        style={{ marginBottom: "44px" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("changePwd.require_msg1")
                                            },
                                            {
                                                pattern: PassWordRegular(),
                                                message: t("password_verify")
                                            }
                                        ]}>
                                        <Input.Password placeholder={t("changePwd.old_password")} />
                                    </Form.Item>
                                    <Form.Item
                                        name="newm"
                                        style={{ marginBottom: "44px" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("changePwd.require_msg2")
                                            },
                                            {
                                                pattern: PassWordRegular(),
                                                message: t("password_verify")
                                            }
                                        ]}>
                                        <Input.Password placeholder={t("changePwd.new_password")} />
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        <Button htmlType="button" onClick={backSettings}>
                                            {t("changePwd.back")}
                                        </Button>
                                        <Button type="primary" htmlType="submit" style={{ backgroundColor: "#189A1B" }}>
                                            {t("forget.submit")}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ChangePwd;
