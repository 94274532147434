export default {
    user_not_login: "The user is not logged in and cannot access",
    login_date: "Log in expired, please log in again",
    token_failure: "token failure",
    bad_request: "Bad request",
    not_found: "Request error, resource not found",
    no_allowance: "The request method is not allowed",
    request_timeout: "request timeout",
    server_error: "Server side error",
    network_not_implemented: "Network not implemented",
    network_error: "network error",
    service_unavailable: "service unavailable",
    network_timeout: "network timeout",
    version_does: "The request is not supported by the network protocol",
    connection_error: "connection error"
};
