export default {
    invalid_token: "Invalid Token",
    User_Password_Not_Match: "User Password Does Not Match",
    Create_Token_Failed: "Create Token Failed",
    token_expired: "Token Expired",
    permission_check_failed: "Permission Check Failed",
    permission_deny: "Permission Denied",

    unknown_auth_type: "unknown auth type",

    duplicated_user_email: "Account already exists",
    add_user_failed: "add user failed",
    user_not_found: "user not found",
    user_blocked: "user blocked",

    user_email_was_verified: "user email has been verified",
    generate_email_confirm_code_failed: "Email request to server failed, please try again later",
    send_email_failed: "send email failed",
    email_confirm_id_not_match: "email confirmation id does not match",
    email_confirm_no_code: "no email confirmation code",
    email_confirm_code_not_match: "email confirmation code does not match",

    update_user_info_failed: "update user info failed",
    user_info_no_update: "user info not updated",

    can_not_reset_password_before_verify_email: "cannot reset password before email verification",
    reset_password_confirm_no_code: "reset password confirm no code",
    reset_password_code_not_match: "reset password code not match",

    export_user_data_too_frequency: "export user data too frequency",

    mark_user_to_delete_failed: "mark user to delete failed",
    user_not_founded: "user_not_founded",

    cache_get_failed: "cache get failed",
    cache_decode_failed: "cache decode failed",
    cache_set_failed: "cache set failed",
    cache_encode_failed: "cache encode failed",
    cache_not_found: "cache not found",
    cache_delete: "cache delete",
    cache_lock_failed: "cache lock failed",

    send_email_too_frequency: "send email too frequency",

    unsupported_authorize_response_type: "unsupported authorize response type",
    auth_app_not_found_for_given_client_id: "auth app not found for given client id",
    unsupported_grant_type: "unsupported grant type",
    authorization_code_not_found: "authorization code not found",
    client_id_not_match: "client id not match",
    invalid_client_secret: "invalid client secret",
    apply_refresh_token_failed: "apply refresh token failed",
    apply_access_token_failed: "apply access token failed",
    check_authorization_code_failed: "check authorization code failed",

    refresh_token_not_found: "refresh token not found",
    revoke_access_token_failed: "revoke access token failed",

    create_oauth_user_failed: "create oauth user failed",
    generate_authorize_code_failed: "generate authorize code failed",

    invalid_authorization_payload: "invalid authorization payload",
    invalid_access_token: "invalid access token",
    access_token_not_found: "access token not found",

    user_has_been_blocked: "user has been blocked",
    user_block_failed: "user block failed",
    user_unblock_failed: "user unblock failed",

    decode_user_failed: "decode user failed",
    list_user_failed: "list user failed",

    duplicated_oauth_app_id: "duplicated oauth app id",
    add_oauth_app_failed: "add oauth app failed",
    oauth_app_not_found: "oauth app not found",
    delete_oauth_app_failed: "delete oauth app failed",
    decode_oauth_app_failed: "decode oauth app failed",
    list_oauth_app_failed: "list oauth app failed",
    oauth_app_not_found_to_delete: "oauth app not found to delete",

    update_oauth_app_info_failed: "update oauth app info failed",
    oauth_app_info_no_update: "oauth app info no update",

    decode_oauth_app_secret_failed: "decode oauth app secret failed",
    list_oauth_app_secret_failed: "list oauth app secret failed",
    duplicated_oauth_app_secret_id: "duplicated oauth app secret id",
    add_oauth_app_secret_failed: "add oauth app secret failed",
    delete_oauth_app_secret_failed: "delete oauth app secret failed",
    oauth_app_secret_not_found: "oauth app secret not found",

    err: "failed, please try again later"
};
